import React from "react"
import styled from "styled-components"
import Resources from '../../resources/afiservicios-resources.json'

const DividerVerticalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DividerVerticalBorder = styled.div`
  display: block;
  height: 100%;
  width: ${Resources.divider_vertical.width};
  border: 1px solid var(${Resources.divider_vertical.color});
  background-color: var(${Resources.divider_vertical.color});
`;

const DividerVertical = () => (
  <DividerVerticalWrapper>
    <DividerVerticalBorder />
  </DividerVerticalWrapper>
);

export default DividerVertical;
