import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const SpecialtyAreaWrapper = styled.div`
  background-color: var(${Resources.finance_structure.specialty_area.bg_color});
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 15px;
  margin: 15px auto;
  display: grid;
  grid-template-columns: 1fr;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    margin: 10px auto;
  }
`;

const SpecialtyAreaContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpecialtyAreaText = styled.span`
  color: var(${Resources.finance_structure.specialty_area.text_color});
  font-family: var(${Resources.finance_structure.specialty_area.font});
  font-size: ${Resources.finance_structure.specialty_area.font_size};
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.finance_structure.specialty_area.font_size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.finance_structure.specialty_area.font_size_med};
  }
`

const SpecialtyArea = ({ type, label }) => {
  return (
    <StaticQuery
      query={graphql`
      query {
        money: file(relativePath: { eq: "money.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        user: file(relativePath: { eq: "user.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        law: file(relativePath: { eq: "law.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => {
        const { fluid } = data[type].childImageSharp

        return (
          <div style={{ marginTop: '30px' }}>
            <SpecialtyAreaWrapper>
              <SpecialtyAreaContent>
                <Img
                  fluid={fluid}
                  imgStyle={{ objectFit: "contain", width: "100%", height: "100%" }}
                  style={{ width: "70px", height: "70px" }}
                />
              </SpecialtyAreaContent>
            </SpecialtyAreaWrapper>
            <SpecialtyAreaText>{label}</SpecialtyAreaText>
          </div>
        )
      }
      } />
  )
}

SpecialtyArea.propTypes = {
  type: PropTypes.oneOf([
    "money",
    "user",
    "law"
  ]).isRequired
}

export default SpecialtyArea
