import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import Zoom from "react-reveal/Zoom"
import { useSiteContent } from "../hooks/use-site-content"
import bullet2 from "../images/bullet2.png"

const ServicesSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr ;
  grid-gap: 1em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`

const ServicesSectionContractsTextContainer = styled.div``

const ServicesSectionContractsContainer = styled.div``

const ServicesSectionTitle = styled.label`
  font-family: var(${Resources.services.title.font});
  font-size: ${Resources.services.title.size};
  color: var(${Resources.services.title.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.services.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.services.title.size_med};
  }
`

const ServicesSectionDescription = styled.div`
  margin-top: 40px;
  font-family: var(${Resources.services.description.font});
  font-size: ${Resources.services.description.size};
  color: var(${Resources.services.description.color});
  line-height: ${Resources.services.description.line_height};

ul {
    list-style: none;
  }
  
  li::before {
    width: 1em;
    height: 1em;
    margin-left: -3em;
    content: '';
    padding-right: 3em;
    background-repeat: no-repeat;
    background-image: url(${bullet2});
    background-size: contain;
  
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.services.description.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.services.description.size_med};
  }
`


const ServicesSection = () => {
  const {services : {contracts}} = useSiteContent()
  return(
    <div className="container">
      <ServicesSectionWrapper>
        <Zoom>
          <ServicesSectionContractsContainer id="services-id">
            <ServicesSectionContractsTextContainer>
              <ServicesSectionTitle>
                {Resources.services.contracts.title}
              </ServicesSectionTitle>
              <ServicesSectionDescription dangerouslySetInnerHTML={{__html: contracts}}/>
            </ServicesSectionContractsTextContainer>
          </ServicesSectionContractsContainer>
        </Zoom>

      </ServicesSectionWrapper>
    </div>
  )
}

export default ServicesSection
