import React from "react";
import styled from "styled-components";
import bullet2 from "../images/bullet2.png"
import PropTypes from 'prop-types'

const CustomListWrapper = styled.ul`
  list-style: none;
  padding-left: 3em;
`;


const CustomListItem = styled.li`
  line-height: 30px;
  margin-bottom: 15px;
  /*display: flex;*/
  justify-content: flex-start;
  align-items: center;
  color: var(${props => props.color});
  
  ::before {
    width: 1em;
    height: 1em;
    margin-left: -3em;
    content: '';
    padding-right: 3em;
    background-repeat: no-repeat;
    background-image: url(${bullet2});
    background-size: contain;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const CustomList = ({ list, color }) => (
  <CustomListWrapper>
    {
      list.map((label, key) =>
        <CustomListItem key={key} color={color}>
          {label}
        </CustomListItem>
      )
    }
  </CustomListWrapper>
);

CustomList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string
};

CustomList.defaultProps = {
  color: '--six-color'
};

export default CustomList;
