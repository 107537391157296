import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Fade from 'react-reveal/Fade'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const SecuritizationSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`

const SecuritizationImageContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin-right: -28%;

  .img-wrapper {
    max-width: 100%;
    max-height: 600px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-column: 1;
    grid-row: 2;
    margin-right: -11%;

    .img-wrapper {
      margin-left: 10%;
      max-height: 300px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-column: 1;
    grid-row: 2;
    margin-right: -4%;

    .img-wrapper {
      max-height: 400px;
      border-radius: 0%;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-right: -7%;

    .img-wrapper {
      max-height: 600px;
    }
  }

  /* XLarge devices */
  @media only screen and (min-width: 1460px) {
    .img-wrapper {
      border-radius: 0%;
    }
  }
`

const SecuritizationTextContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`

const SecuritizationTitle = styled.label`
  font-family: var(${Resources.experience.securitization.title.font});
  font-size: ${Resources.experience.securitization.title.size};
  color: var(${Resources.experience.securitization.title.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.experience.securitization.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.experience.securitization.title.size_med};
  }
`

const SecuritizationDescription = styled.div`
  width: 480px;
  margin: 40px 0 0;
  margin-top: 40px;
  font-family: var(
    ${Resources.experience.securitization.description.font}
  );
  font-size: ${Resources.experience.securitization.description.size};
  color: var(${Resources.experience.securitization.description.color});
  line-height: ${Resources.experience.securitization.description
    .line_height};

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.experience.securitization.description
      .size_small};
    width: 80vw;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.experience.securitization.description
      .size_med};
    width: 80vw;
  }

  @media only screen and (max-width: 767px) {
    .react-multi-carousel-dot-list.custom-dot-list-style {
      margin-top: 0 !important;
    }
  }

  .react-multi-carousel-dot-list.custom-dot-list-style {
    position: relative !important;
    margin-top: 30px;
  }
`

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  }
}

const CustomDotButton = styled.button `
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	opacity: 1;
	padding: 5px 5px 5px 5px;
	box-shadow: none;
	transition: background .5s;
	border-width: 0;
	padding: 0;
	margin: 0;
	margin-right: 6px;
	outline: 0;
  cursor: pointer;
  background: var(--primary-third-color);
  
  :hover{
  	background: var(--primary-dark-color);
  }
  
  &.active {
  	background: var(--primary-dark-color);
  }
`

const CustomDot = ({ onClick, ...rest }) => {
  const {
    active
  } = rest;
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <CustomDotButton
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    >
    </CustomDotButton>
  );
};

const SecuritizationSection = () => {
  console.log(useSiteContent())

  const {experience: {securitization}} = useSiteContent();
  let elems = [];
  for(let i = 0; i < Math.ceil(securitization.length/4) ; i++) {
    const sliced = securitization.slice(4 * i,4 * (i+1));
    const internalElem = (
      <div key={i}>
        <CustomList list={sliced}/>
      </div>
    );
    elems.push(internalElem);
  }
  return (
    <StaticQuery
      key={'background_img'}
      query={graphql`
      query {
        img: file(name: { eq: "securitization_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 873) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <div className="container" id="securitization-id">
          <SecuritizationSectionWrapper>
            <Fade right>
              <SecuritizationTextContainer>
                <SecuritizationTitle>
                  {Resources.experience.securitization.title.label}
                </SecuritizationTitle>
                <SecuritizationDescription>
                  <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    autoPlaySpeed={Resources.experience.securitization.description.time_milliseconds}
                    infinite={true}
                    showDots={true}
                    swipeable={true}
                    draggable={true}
                    dotListClass="custom-dot-list-style"
                    customDot={<CustomDot />}
                    removeArrowOnDeviceType={[
                      "superLargeDesktop",
                      "desktop",
                      "tablet",
                      "mobile",
                    ]}
                    ssr={true}
                    renderDotsOutside={true}
                  >
                    {elems}
                  </Carousel>
                </SecuritizationDescription>
              </SecuritizationTextContainer>
              <SecuritizationImageContainer>
                <Img
                  fluid={data.img.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    objectPosition: "center bottom",
                  }}
                  className="img-wrapper"
                />
              </SecuritizationImageContainer>
            </Fade>
          </SecuritizationSectionWrapper>
        </div>
      )}
    />
  )
}

export default SecuritizationSection
