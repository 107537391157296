import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import SpecialtyArea from "./specialty-area"
import bullet2 from "../images/bullet2.png"
import DividerVertical from "./divider-vertical"
import Fade from "react-reveal/Fade"
import { useSiteContent } from "../hooks/use-site-content"

const FinanceStructureSectionWrapper = styled.div`
  background-color: var(${Resources.finance_structure.bg_color});
`

const FinanceStructureSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  grid-gap: 1em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
`

const FinanceStructureTitle = styled.label`
  font-family: var(${Resources.finance_structure.title.font});
  font-size: ${Resources.finance_structure.title.size};
  color: var(${Resources.finance_structure.title.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.finance_structure.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.finance_structure.title.size_med};
  }
`

const FinanceStructureDescription = styled.div`
  margin-top: 40px;
  font-family: var(${Resources.finance_structure.description.font});
  font-size: ${Resources.finance_structure.description.size};
  color: var(${Resources.finance_structure.description.color});
  line-height: ${Resources.finance_structure.description.line_height};

  ul {
    list-style: none;
  }

  li::before {
    width: 1em;
    height: 1em;
    margin-left: -3em;
    content: "";
    padding-right: 3em;
    background-repeat: no-repeat;
    background-image: url(${bullet2});
    background-size: contain;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.finance_structure.description.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.finance_structure.description.size_med};
  }
`

const SpecialtyAreasContainer = styled.div`
  text-align: center;

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: left;
  }
`

const SpecialtyAreasItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: block;
    text-align: center;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }
`

const FinanceStructureSubTitle = styled.label`
  font-family: var(${Resources.finance_structure.subtitle.font});
  font-size: ${Resources.finance_structure.subtitle.size};
  color: var(${Resources.finance_structure.subtitle.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.finance_structure.subtitle.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.finance_structure.subtitle.size_med};
  }
`

const FinanceStructureSection = () => {
  const {
    finance_structures,
    areas_esp: { label_1, label_2, label_3 },
  } = useSiteContent()

  return (
    <FinanceStructureSectionWrapper>
      <div className="container">
        <FinanceStructureSectionContainer>
          <Fade left>
            <div>
              <FinanceStructureTitle>
                {Resources.finance_structure.title.label}
              </FinanceStructureTitle>
              <FinanceStructureDescription
                dangerouslySetInnerHTML={{
                  __html: finance_structures.description,
                }}
              />
            </div>
          </Fade>

          <DividerVertical />
          <Fade right>
            <SpecialtyAreasContainer>
              <FinanceStructureSubTitle>
                {Resources.finance_structure.subtitle.label}
              </FinanceStructureSubTitle>
              <SpecialtyAreasItemsContainer style={{ marginTop: "10px" }}>
                <SpecialtyArea type="money" label={label_1} />
                <SpecialtyArea type="user" label={label_2} />
                <SpecialtyArea type="law" label={label_3} />
              </SpecialtyAreasItemsContainer>
            </SpecialtyAreasContainer>
          </Fade>
        </FinanceStructureSectionContainer>
      </div>
    </FinanceStructureSectionWrapper>
  )
}

export default FinanceStructureSection
