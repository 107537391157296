import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Fade from "react-reveal/Fade"
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const FinancingMainDiv = styled.div`
  background-color: var(${Resources.experience.financing.bg_color});
  width: 100%;
`

const FinancingSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    margin-top: 100px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
`

const FinancingSectionImageContainer = styled.div`
  margin-left: -28%;
  grid-column: 1;
  grid-row: 1;

  .img-wrapper {
    max-width: 100%;
    max-height: 700px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    margin-right: -11%;
    margin-top: -60px;
    grid-row: 2;
    /*grid-column: 1;*/

    .img-wrapper {
      margin-right: 10%;
      height: 300px;
      /*max-height: 300px;
      min-height: 300px;*/
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: -4%;
    margin-left: 0;
    margin-top: -120px;
    grid-row: 2;
    /*grid-column: 1;*/

    .img-wrapper {
      /*margin-left: 29%;*/
      height: 400px;
      /*max-height: 400px;*/
      border-radius: 0%;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-left: -7%;

    .img-wrapper {
      /*margin-right: 0;*/
      height: 600px;
      /*max-height: 600px;
      min-height: 500px;*/
    }
  }

  /* XLarge devices */
  @media only screen and (min-width: 1460px) {
    .img-wrapper {
      height: 600px;
      border-radius: 0%;
    }
  }
`

const FinancingSectionTitle = styled.label`
  font-family: var(${Resources.experience.financing.title.font});
  font-size: ${Resources.experience.financing.title.size};
  color: var(${Resources.experience.financing.title.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.experience.financing.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.experience.financing.title.size_med};
  }
`

const FinancingSectionDescription = styled.div`
  width: 480px; /*550px*/
  margin-top: 40px;
  font-family: var(${Resources.experience.financing.description.font});
  font-size: ${Resources.experience.financing.description.size};
  color: var(${Resources.experience.financing.description.color});
  line-height: ${Resources.experience.financing.description
    .line_height};

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.experience.financing.description.size_small};
    width: 80vw;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.experience.financing.description.size_med};
    width: 80vw;
  }

  @media only screen and (max-width: 767px) {
    .react-multi-carousel-dot-list.custom-dot-list-style {
      margin-top: 0 !important;
    }
  }

  .react-multi-carousel-dot-list.custom-dot-list-style {
    position: relative !important;
    margin-top: 30px;
  }
`

const FinancingSectionTextContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  /*padding-left: 30px;*/

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 60px;
  }
`

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const CustomDotButton = styled.button`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background-color 0.5s;
  border-width: 0;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
  background: var(--primary-third-color);

  :hover {
    background: var(--primary-dark-color);
  }

  &.active {
    background: var(--primary-dark-color);
  }
`

const CustomDot = ({ onClick, ...rest }) => {
  const {
    active
  } = rest
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <CustomDotButton
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    />
  )
}

const FinancingSection = () => {
  const {experience: {financing}} = useSiteContent();
  let elems = [];
  for(let i = 0; i < Math.ceil(financing.length/4) ; i++) {
    const sliced = financing.slice(4 * i,4 * (i+1));
    const internalElem = (
      <div key={i}>
        <CustomList list={sliced}/>
      </div>
    );
    elems.push(internalElem);
  }

  return(
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "financing_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => {

        return (
          <FinancingMainDiv>
            <div className="container" id="finance-id">
              <FinancingSectionWrapper>
                <FinancingSectionImageContainer>
                  <Fade left>
                    <Img
                      fluid={data.img.childImageSharp.fluid}
                      imgStyle={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        objectPosition: "center top",
                      }}
                      className="img-wrapper"
                    />
                  </Fade>
                </FinancingSectionImageContainer>
                <Fade left>
                  <FinancingSectionTextContainer>
                    <FinancingSectionTitle>
                      {Resources.experience.financing.title.label}
                    </FinancingSectionTitle>
                    <FinancingSectionDescription>
                      <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={
                          Resources.experience.financing.description
                            .time_milliseconds
                        }
                        infinite={true}
                        showDots={true}
                        swipeable={true}
                        draggable={true}
                        dotListClass="custom-dot-list-style"
                        customDot={<CustomDot />}
                        removeArrowOnDeviceType={[
                          "superLargeDesktop",
                          "desktop",
                          "tablet",
                          "mobile",
                        ]}
                        ssr={true}
                        renderDotsOutside={true}
                      >
                        {elems}
                      </Carousel>
                    </FinancingSectionDescription>
                  </FinancingSectionTextContainer>
                </Fade>
              </FinancingSectionWrapper>
            </div>
          </FinancingMainDiv>
        )
      }}
    />
  )
}

export default FinancingSection
