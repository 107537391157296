import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Resources from "../../resources/afiservicios-resources.json"
import { graphql, StaticQuery } from "gatsby"
import CustomButton from "./button"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import BackgroundImage from "gatsby-background-image"
import "../styles/background-image.css"
import Fade from "react-reveal/Fade"
import { useSiteContent } from "../hooks/use-site-content"
import Img from "gatsby-image"

const CoverWrapper = styled.div`
  overflow: hidden;
  margin-top: -80px;
`
const CoverInfoSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  padding-top: 65px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
    height: calc(100vh - 80px);
    overflow: hidden;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    padding-top: 92px;
  }
`

const CoverContentContainer = styled.div`
  min-height: calc(100vh - 80px);
  height: auto;
`

const CoverContentSection = styled.div`
  display: flex;
  grid-template-columns: 1fr;
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  grid-gap: 1em;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 991px) {
    display: flex;
    align-items: center;
  }
`

const CoverTextContainer = styled.div`
  display: block;
  width: 100%;
  margin: auto 0;
`

const CoverTextDisplay = styled.div`
  text-align: left;
  margin: 30px 0;

  .cover-title {
    font-family: var(${Resources.cover.title.font});
    font-size: ${Resources.cover.title.size};
    color: var(${Resources.cover.title.color});
    text-transform: uppercase;
    display: block;
    line-height: normal;
    max-width: 400px;
  }

  .cover-subtitle {
    font-family: var(${Resources.cover.subtitle.font});
    font-size: ${Resources.cover.subtitle.size};
    color: var(${Resources.cover.subtitle.color});
    line-height: ${Resources.cover.subtitle.line_height};
    padding-bottom: 10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_small};
      max-width: 100%;
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_small};
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_med};
      max-width: 100%;
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_med};
    }
  }
`

const CoverTextDisplayCarousel = styled(CoverTextDisplay)`
  width: 450px;
  margin: 0;
  .react-multi-carousel-dot-list.custom-dot-list-style {
    justify-content: start;
    margin-top: 15px;
    position: relative !important;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 991px) {
    width: 80vw;
    .react-multi-carousel-dot-list.custom-dot-list-style {
      justify-content: center;
    }
  }
`

const CoverTextDispButton = styled(CoverTextDisplay)`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const DisplayBadgesContainer = styled.div`
  position: fixed !important;
  gap: 10px;
  top: 0;
  right: 20px;
  z-index: 200;
  display: flex;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    right: auto;
    left: calc(50% - 150px);
  }
`

const CoverGPTW = styled(Img)`
  width: 100px;
  height: auto;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    width: 80px;
  }
`

const CoverISO = styled(Img)`
  width: 100px;
  height: 100px;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};
  margin-left: 10px;

  @media only screen and (max-width: 991px) {
    width: 80px;
    height: 80px;
  }
`

const CoverESR = styled(Img)`
  width: 100px;
  height: 100px;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};
  margin-left: 10px;

  @media only screen and (max-width: 991px) {
    width: 80px;
    height: 80px;
  }
`

const CustomDotButton = styled.button`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background-color 0.5s;
  border-width: 0;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;

  :hover {
    background: var(--primary-color);
  }

  &.active {
    background: var(--primary-color);
  }
`

const CustomDot = ({ onClick, ...rest }) => {
  const { active } = rest
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <CustomDotButton
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    ></CustomDotButton>
  )
}

const Cover = () => {
  const siteContent = useSiteContent()
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    const { scrollY } = window
    if (scrollY >= 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          cover: file(name: { eq: "cover_background" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          gptw: file(name: { eq: "gptw" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          iso: file(name: { eq: "iso" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          esr: file(name: { eq: "esr" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <CoverWrapper>
          <DisplayBadgesContainer>
            <CoverISO
              scrolled={scrolled}
              fluid={data.iso.childImageSharp.fluid}
            />
            <CoverESR
              scrolled={scrolled}
              fluid={data.esr.childImageSharp.fluid}
            />
            <CoverGPTW
              scrolled={scrolled}
              fluid={data.gptw.childImageSharp.fluid}
            />
          </DisplayBadgesContainer>
          <BackgroundImage
            Tag={"div"}
            className={"bg-cover"}
            fluid={data.cover.childImageSharp.fluid}
            backgroundColor={`var(${Resources.cover.bg_color})`}
          >
            <CoverInfoSection>
              <div className="container">
                <CoverContentContainer>
                  <CoverContentSection>
                    <CoverTextContainer>
                      <CoverTextDisplay>
                        <span className="cover-title">
                          {siteContent.cover.title}
                        </span>
                      </CoverTextDisplay>
                      <CoverTextDisplayCarousel>
                        <Carousel
                          responsive={responsive}
                          autoPlay={true}
                          autoPlaySpeed={
                            Resources.cover.subtitle.time_milliseconds
                          }
                          infinite={true}
                          showDots={true}
                          swipeable={true}
                          draggable={true}
                          dotListClass="custom-dot-list-style"
                          customDot={<CustomDot />}
                          removeArrowOnDeviceType={[
                            "superLargeDesktop",
                            "desktop",
                            "tablet",
                            "mobile",
                          ]}
                          ssr={true}
                          renderDotsOutside={true}
                        >
                          {siteContent.cover.carousel.map((label, key) => (
                            <span key={key} className={"cover-subtitle"}>
                              {label}
                            </span>
                          ))}
                        </Carousel>
                      </CoverTextDisplayCarousel>
                      <CoverTextDispButton>
                        <CustomButton
                          label={Resources.cover.button.label}
                          view={Resources.cover.button.view}
                          fullWidth={false}
                          containerStyle={{ marginTop: "30px" }}
                          onClick={() => onContactClick()}
                        />
                      </CoverTextDispButton>
                    </CoverTextContainer>
                    <div />
                  </CoverContentSection>
                </CoverContentContainer>
              </div>
            </CoverInfoSection>
          </BackgroundImage>
        </CoverWrapper>
      )}
    />
  )
}

const onContactClick = () => {
  const element = document.getElementById("contact-form-id")
  if (element) {
    const position = element.offsetTop - Resources.navbar.offset_top
    window.scrollTo(0, position)
  }
}

export default Cover
